<template>
  <PageWithLayout>
    <template v-if="viewModel.IS_USE_BANK">
    <TableView>
        <template v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">현재 사용 중인 은행</strong>
          </div>
        </template>
      <template v-slot:tbl_body>
          <tr>
            <th>소유주명</th>
            <td>{{viewModel.IS_USE_BANK.ownerName}}</td>
            <th>상태</th>
            <td>{{viewModel.IS_USE_BANK.status | convertIdToText('bank_status')}}</td>
          </tr>
          <tr>
            <th>은행ID</th>
            <td>{{viewModel.IS_USE_BANK.bankCompanyId}}</td>
            <th>계좌번호</th>
            <td>{{viewModel.IS_USE_BANK.accountNumber}}</td>
          </tr>
          <tr>
            <th>uid</th>
            <td>{{viewModel.IS_USE_BANK.uid}}</td>
            <th>처리기관코드</th>
            <td>{{viewModel.IS_USE_BANK.trtInstCd}}</td>
          </tr>
          <tr>
            <th>생성일시</th>
            <td>{{viewModel.IS_USE_BANK.createdAt | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
            <th>업데이트일시</th>
            <td>{{viewModel.IS_USE_BANK.updatedAt | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
          </tr>
      </template>
    </TableView>
  </template>

    <TableView v-for="(bankData, index) in viewModel.SELETE_BANK_LIST"
        :key="bankData.id">
        <template v-if="index === 0" v-slot:tbl_top>
          <div class="area_left">
            <strong class="tit_tbl">변경할 입금이체용 은행 선택</strong>
          </div>
        </template>
      <template v-slot:tbl_head>
          <th colspan="4">
            <RadioItem
              :item="bankData"
              :id="bankData.id"
              :value.sync="viewModel.SELETED_BANK_ID"
              :text="bankData.ownerName"
              name="changedBank"/>
          </th>
      </template>
      <template v-slot:tbl_body>
          <tr>
            <th>상태</th>
            <td colspan="3">{{bankData.status | convertIdToText('bank_status')}}</td>
          </tr>
          <tr>
            <th>은행ID</th>
            <td>{{bankData.bankCompanyId}}</td>
            <th>계좌번호</th>
            <td>{{bankData.accountNumber}}</td>
          </tr>
          <tr>
            <th>uid</th>
            <td>{{bankData.uid}}</td>
            <th>처리기관코드</th>
            <td>{{bankData.trtInstCd}}</td>
          </tr>
          <tr>
            <th>생성일시</th>
            <td>{{bankData.createdAt | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
            <th>업데이트일시</th>
            <td>{{bankData.updatedAt | convertDateFormat('YYYY-MM-DD HH:mm:ss')}}</td>
          </tr>
      </template>
    </TableView>

    <Sticky>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary_border"
          text="변경하기"
          :disabled="!viewModel.SELETED_BANK_ID"
          @onClickBtn="viewModel.onClickChangeBank()"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import RadioItem from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/RadioItem';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import BankViewModel from '@/views/service/bank/viewModel/BankViewModel'

export default {
  name: 'BankChange',
  components:{
    PageWithLayout,
    TableView,
    RadioItem,
    Sticky,
    Button,
  },
  beforeMount(){
    this.viewModel.init();
  },
  data(){
    return{
      viewModel: new BankViewModel(),
    }
  },
}
</script>
<style scoped>
.tbl_view thead th{padding:8px 20px;background-color:#e5e5e5;color:#fff;text-align:left;}
.tbl_view thead th >>> .radio_comm .icon_radio{border-color:#999}
@media (prefers-color-scheme: dark) {
  .tbl_view thead th{background-color:#444}
}
</style>