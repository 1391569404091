
import apiPath from '@/services/apiPath';
import { GET_REQUEST, PUT_REQUEST } from '@/services/http';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class BankViewModel {
  constructor() {
    this.IS_USE_BANK = {};
    this.SELETE_BANK_LIST = [];
    this.SELETED_BANK_ID = null;
  }

  init(){
    this.getAvailableBank();
  }
  onClickChangeBank(){
    if(this.SELETED_BANK_ID){
      const reqeustChangeBank = () => {
        this.putChangeBank(this.SELETED_BANK_ID)
      }

      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `입금이체용 은행을 정말 바꾸시겠습니까?`,
        closeBtnText: '취소',
        compeleteBtnText: '변경하기',
        confirmCallBack: reqeustChangeBank,
      });
    }else{
      store.dispatch('commonToast/fetchToastStart', '변경할 은행을 선택해주세요');
    }
  }
  // [API] 현재 퍼핀에서 사용하는 입금이체 은행 목록
  getAvailableBank(){
    const path = apiPath.BANK_LIST;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.IS_USE_BANK = resultData.body.banks.find(item => item.isUse);
      this.SELETE_BANK_LIST = resultData.body.banks.filter(item => !item.isUse)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg || '로드에 실패했습니다');
    })
  }
  // [API] 해당 id를 메인으로 설정하고, 나머지는 Disable 시키는 기능
  putChangeBank(id){
    const path = apiPath.BANK_CHANGE.format(id);
    PUT_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      console.log(resultData)
      if(resultData && resultData.banks){
        this.IS_USE_BANK = resultData.banks.find(item => item.isUse);
        this.SELETE_BANK_LIST = resultData.banks.filter(item => !item.isUse)
      }
      store.dispatch('commonToast/fetchToastStart', '은행을 변경하였습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg || '로드에 실패했습니다');
    })
  }

}
